import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '@features/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanGuard {
  defaultRedirectUrl = '/app';
  trialEndedRedirectUrl = '/trial-ended';

  constructor(
    private _auth: AuthService,
    protected router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path = next.routeConfig.path;
    if (path === 'trial-ended') {
      return this._auth.isPlanLead() ? of(true) : of(this.router.parseUrl(this.defaultRedirectUrl));
    } else {
      return this._auth.isPlanLead() ? of(this.router.parseUrl(this.trialEndedRedirectUrl)) : of(true);
    }
  }
}
